import React from "react";
import "./home.scss";

const Home = () => {
  return (
    <div>
      <section className="first-section">
        <h1 className="name">Jakob Persson</h1>
        <h1 className="skills">
          UX/UI Designer | Developer | Graphics | Photographer
        </h1>
      </section>

      <div className="short-info-box">
        <div className="short-info">
          <h1>Hi, I'm Jakob Persson</h1>
          <p>
            I'm working at Solidsport as an developer, UX/UI Designer and I have a Master of Engineering degree in
            interaction and design at Umeå University. What interests me most is UX/UI
            design and front-end development. 
            But besides that, I also do graphic design and photo editing in my spare-time.
          </p>
        </div>
        <div className="image-container">
          <div className="top-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-2e0f3.appspot.com/o/jakob-camera.jpg?alt=media&token=c9bc1b23-8a8a-41e1-8143-b4a2fa6c3de2"/>
          </div>
          <div className="bot-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-2e0f3.appspot.com/o/jakob-sun.jpg?alt=media&token=14ea6f1b-3b35-4350-a090-6bbcb0537ec4"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
