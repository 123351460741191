import React from "react";
import "./resume.scss";
import ProgramingSkills from "./programing-language/pl";
import DesignSkills from "./design-skills/design-skills";
import Work from "./work/work";
import Education from "./education/education";
const Resume = () => {
  return (
    <div className="resume">
      <div className="top">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/portfolio-2e0f3.appspot.com/o/Profilbild.jpg?alt=media&token=e581cf50-f275-4d0a-a65b-a0206e3b97ca"
          alt="Jakob Persson"
        />
        <div className="top-text">
          <h1>Jakob Persson</h1>
          <h4>Student - Master of Engineering in Interaction & Design</h4>
        </div>
      </div>
      <Education />
      <ProgramingSkills />
      <DesignSkills />
      <Work />
      <div className="downloadRes">
        <a
          href="https://firebasestorage.googleapis.com/v0/b/portfolio-2e0f3.appspot.com/o/CV-Jakob-Persson-2021.pdf?alt=media&token=66ede273-0120-47e7-a708-a1ad59d0eb14"
          target="_blank"
          download
        >
          <div className="download-button">
            {" "}
            <p>Download CV (SV)</p>
          </div>
        </a>
        <a
          href="https://firebasestorage.googleapis.com/v0/b/portfolio-2e0f3.appspot.com/o/CV-Jakob-Persson-2021-eng.pdf?alt=media&token=33a8ea8d-71c9-44fd-ab30-c99556ce4474"
          target="_blank"
          download
        >
          <div className="download-button">
            {" "}
            <p>Download CV (EN)</p>
          </div>
        </a>
      </div>
    </div>
  );
};
export default Resume;
