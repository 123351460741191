import React from "react";
import "./about.scss";

const About = () => {
  const calculateAge=(birthday)=> { 
    const diff_ms = Date.now() - birthday.getTime();
    const age_dt = new Date(diff_ms); 
  
    return Math.abs(age_dt.getUTCFullYear() - 1970);
}
const age = calculateAge(new Date(1994, 5, 13))

  return (
    <section className="about">
      <div className="header">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/portfolio-2e0f3.appspot.com/o/hockey-me.jpg?alt=media&token=42fbbb4e-22c5-439b-b751-d42d19ec161b"
          alt="hockeyimage on Jakob Persson"
        />
        <div className="text">
          <h2> Hi, I'm Jakob Persson!</h2>
          <p>
            I am {age} years old and grew up in Stockholm. I have 
            a master's degree in Interaction, Technology, and design at Umeå University.
            Right now I am working as a developer and UX/UI designer at Solidsport in Stockholm. 
            I am most interested in UX / UI, front-end,
            and graphical design.  <br />
            <br />

            Since I was six years old, I have played ice hockey, which is a big
            interest for me. Right now I play for Tullinge TP as a defensemen. 
            In addition to my great interest in ice hockey, I also like
            cross-country skiing, slalom and being out in nature. When I'm not
            playing sports, I also enjoy photography. I have photographed
            football matches from Allsvenskan and various events that have
            been held at Umeå University, including Idag &amp; Inatt and Datatjej
            2019. Feel free to check out my photos in my flickr which is linked
            in the footer.
          </p>
        </div>
      </div>
    </section>
  );
};
export default About;
